<template>
  <div>
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        class="mr-2"
        :contentBtnAdd="''"
        :hideDelete="false"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        :arrayExcel="[]"
        @clickDelete="() => {}"
        @clickDowloadSample="() => {}"
        @clickExportExcel="() => {}"
        @importFile="() => {}"
        @clickAdd="() => {}"
        @search="search($event)"
      />
      <vue-good-table
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
          initialSortBy: [
            {field: 'name', type: 'asc'},
            {field: 'code', type: 'desc'}
          ],
        }"
        :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'lastDateTime'">
            <span>
              {{ props.row.lastDateTime | formatDateToDDMMHHMM }}
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMMHHMM } from '@core/utils/filter'
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMMHHMM,
  },

  //= =========================== dữ liệu data ==========================//
  data() {
    return {
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'HỌ VÀ TÊN',
          field: 'name',
          tdClass: 'style-code',
        },
        {
          label: 'TÊN ĐĂNG NHẬP',
          field: 'userName',
        },
        {
          label: 'THỜI GIAN',
          field: 'lastDateTime',
        },
      ],
      currentPage: 0,
      totalRecord: 0,
      dataTable: [],
    }
  },

  async created() {
    await this.fetchDataUserTracing(this.urlQuery)
  },

  methods: {
    async fetchDataUserTracing(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_USER_TRACING, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDataUserTracing(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchDataUserTracing(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchDataUserTracing(this.urlQuery)
      }
    },
  },

}
</script>

<style lang="scss">

</style>
